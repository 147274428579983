import React from "react";
import TextHeader from "../textHeader/TextHeader";
import Text from "../text";
import styles from "./jobListing.module.css";
import { useNavigate } from "react-router-dom";

const JobListing = ({ jobTitle, country, location, id }) => {
  const navigate = useNavigate();

  return (
    <div
      className={styles.container}
      onClick={() => navigate(`/job/${id}`, { replace: true })}
    >
      <TextHeader tag="h4">{jobTitle} </TextHeader>
      <Text variant="md" color="secondary" style={{ fontWeight: "300" }}>
        {location}, {country}
      </Text>
    </div>
  );
};

export default JobListing;
