import React, { useEffect, useRef, useState } from "react";
import { HomeHeader } from "../../MainHeader/MainHeader";
import Box from "../../Components/box";
import { Button } from "../../Components/button";
import { icons, images } from "../../assets";
import JobListing from "../../Components/jobListing";
import styles from "./vacancies.module.css";
import TextHeader from "../../Components/textHeader/TextHeader";
import Text from "../../Components/text";
import { useGetJobDetails, usePostCV } from "../../api/apiClients";
import Loading from "../../Components/loading/loading";

const Input = ({ placeholder, ...props }) => {
  return (
    <div className={styles.inputContainer}>
      <input placeholder={placeholder} className={styles.input} {...props} />
    </div>
  );
};

const JobDetailsPage = () => {
  const getJobById = useGetJobDetails();
  const postCV = usePostCV();
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [jobDetails, setJobDetails] = useState(null);
  const [errMssg, setErrorMsg] = useState(null);
  const [status, setStatus] = useState(null);
  const [data, setData] = useState({
    name: "",
    location: "",
    email: "",
    experience: "",
    qualification: ""
  });

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleBrowseClick = () => {
    fileInputRef.current.click();
  };

  const handleUpload = () => {
    if (data.name === "") {
      setErrorMsg("Please input your name");
      return;
    }
    if (data.email === "") {
      setErrorMsg("Please input your email");
      return;
    }
    if (data.experience === "") {
      setErrorMsg("Please input your experience");
      return;
    }
    if (data.location === "") {
      setErrorMsg("Please input your location");
      return;
    }
    if (data.qualification === "") {
      setErrorMsg("Please input your qualification");
      return;
    }
    if (!selectedFile) {
      setErrorMsg("Please upload your Resume/CV");
      return;
    }
    setErrorMsg(null);
    setIsLoading(true);
    // Perform upload logic with the selected file
    if (selectedFile) {
      console.log("Reach here ");
      // Example: You can use the 'FormData' object to send the file to a server
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("name", data.name);
      formData.append("location", data.location);
      formData.append("email", data.email);
      formData.append("experience", data?.experience);
      formData.append("qualification", data?.qualification);
      formData.append("role", jobDetails?.jobTitle);
      formData.append("company", jobDetails?.company);
      // Example: Send the formData to an API endpoint using 'fetch' or 'axios'

      postCV(formData)
        .then((data) => {
          setData({
            name: "",
            location: "",
            email: "",
            experience: "",
            qualification: ""
          });
          setSelectedFile(null);
          setIsLoading(false);
          setStatus(true);
        })
        .catch((error) => {
          setIsLoading(false);
          setStatus(null);
          console.error("Error ", error);
          alert("Error in uploading  CV");
        });

      // For this example, let's log the selected file details
      console.log("Selected File:", selectedFile);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    const currentURL = window.location.href;
    const extractedId = currentURL.substring(currentURL.lastIndexOf("/") + 1);
    getJobById(extractedId)
      .then((res) => {
        setIsLoading(false);
        console.log({ res });
        setJobDetails(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, []);

  return (
    <div style={{ backgroundColor: "#fff", paddingBottom: "5rem" }}>
      <HomeHeader color="#000" />
      <div className="main-padding">
        <div className={styles.header}>
          <div>
            <JobListing
              jobTitle={jobDetails?.jobTitle}
              country={jobDetails?.country}
              location={jobDetails?.location}
            />
          </div>
          <div className={styles.button}>
            <a href="#down">
              <Button
                text="Apply Now"
                bg="#C54920"
                borderColor="#C54920"
                color="white"
                route="#down"
              />
            </a>
          </div>
        </div>
        <div style={{ marginTop: "3rem" }}>
          <div
            // className={` ${styles.contactUs} ${styles.blogDetails} ${styless.blogAPI}`}
            dangerouslySetInnerHTML={{
              __html: jobDetails?.description
                .replace(/&lt;/g, "<")
                .replace(/alt=""/g, `alt="CONCEPT"`)
            }}
          />
        </div>
        <div style={{ marginTop: "3rem" }} id="down">
          <TextHeader tag="h4" style={{ paddingBottom: "1rem" }}>
            Apply
          </TextHeader>
          <Text variant="md" color="secondary" style={{ fontWeight: "300" }}>
            {jobDetails?.jobTitle} at {jobDetails?.company}
          </Text>
          <div className={styles.uploadContainer}>
            <div className={styles.flex60}>
              <Input
                placeholder="Full Name *"
                name="name"
                onChange={(e) => setData({ ...data, name: e.target.value })}
              />
              <Input
                placeholder="Email *"
                name="email"
                onChange={(e) => setData({ ...data, email: e.target.value })}
              />
              <Input
                placeholder="Location *"
                name="location"
                onChange={(e) => setData({ ...data, location: e.target.value })}
              />
              <Input
                placeholder="Experience *"
                name="experience"
                onChange={(e) =>
                  setData({ ...data, experience: e.target.value })
                }
              />
              <Input
                placeholder="Qualification *"
                name="qualification"
                onChange={(e) =>
                  setData({ ...data, qualification: e.target.value })
                }
              />
              <div>
                <div className={`${styles.upload} ${styles.mobile}`}>
                  <img
                    src={images.upload}
                    style={{ width: "50px", height: "50px" }}
                  />
                  <Text color="secondary">
                    {selectedFile ? selectedFile.name : "Resume/CV *"}
                  </Text>
                  <div
                    style={{
                      backgroundColor: "#30BFAB",
                      display: "inline-block",
                      padding: ".8rem 1.5rem",
                      marginTop: "1.5rem"
                    }}
                    onClick={handleBrowseClick}
                  >
                    <input
                      type="file"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                      ref={fileInputRef}
                    />
                    <Text color="white">Browse Files</Text>
                  </div>
                </div>
                <div className={styles.mobile}>
                  <Text style={{ marginTop: "1rem", textAlign: "center" }}>
                    Please upload either: pdf, doc, docx
                  </Text>
                </div>
              </div>
              {isLoading ? (
                <Loading />
              ) : (
                <div className={styles.button}>
                  {status && (
                    <Text
                      variant="md"
                      style={{
                        marginBottom: "1rem",
                        color: "green",
                        fontWeight: "700"
                      }}
                    >
                      Your application is successful
                    </Text>
                  )}
                  {errMssg && (
                    <Text
                      variant="md"
                      style={{
                        marginBottom: "1rem",
                        color: "red",
                        fontWeight: "700"
                      }}
                    >
                      {errMssg}
                    </Text>
                  )}

                  <Button
                    text={isLoading ? "Applying" : "Apply Now"}
                    bg="#C54920"
                    borderColor="#C54920"
                    color="white"
                    noRoute={true}
                    onClick={() => handleUpload()}
                  />
                </div>
              )}
            </div>
            <div className={styles.flex40}>
              <div className={`${styles.upload} ${styles.desktop} `}>
                <img
                  src={images.upload}
                  style={{ width: "50px", height: "50px" }}
                />
                <Text color="secondary">
                  {selectedFile ? selectedFile.name : "Resume/CV *"}
                </Text>
                <div
                  style={{
                    backgroundColor: "#30BFAB",
                    display: "inline-block",
                    padding: ".8rem 1.5rem",
                    marginTop: "1.5rem"
                  }}
                  onClick={handleBrowseClick}
                >
                  <input
                    type="file"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    ref={fileInputRef}
                  />
                  <Text color="white">Browse Files</Text>
                </div>
              </div>
              <div className={styles.desktop}>
                <Text style={{ marginTop: "1rem", textAlign: "center" }}>
                  Please upload either: pdf, doc, docx
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobDetailsPage;
