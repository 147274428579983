/**
 * The API routes endpoints
 */
export class ApiRoutes {
  /**
   * The test base url for the application
   */
  static BASE_URL_TEST = "https://api-eden-care.onrender.com/";

  /**
   * The test base url for the application
   */
  static BASE = "/";

  /**
   * The url for sending CV
   */
  static SendCV = "email/send-email";
}
