import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { icons } from "../assets";
import "./style.scss";

export const HomeHeader = ({ color = "black" }) => {
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  const handleClick = () => {
    setClick(!click);
  };

  return (
    <div>
      <header>
        <div className="main-header">
          <NavLink to="/">
            <div>
              <img src={icons.logo} alt="LOGO" />
            </div>
          </NavLink>
          <div className="link">
            <ul className="main-links">
              <NavLink exact activeclassname="active" to="/">
                <li style={{ color: color }}>About us </li>
              </NavLink>
              <NavLink activeclassname="active" to="/recruitment">
                <div className="solution-header">
                  <ol className="dropdown-header">
                    <li style={{ color: color }}>Solutions</li>
                    <li>
                      <img src={icons.dropdown} alt="dropdown" />
                    </li>
                  </ol>
                  <ol className="subRoute">
                    <NavLink activeclassname="active" to="/recruitment">
                      <li>Recruitment</li>
                    </NavLink>
                    <NavLink activeclassname="active" to="/resource-consulting">
                      <li>Human Resource Consulting</li>
                    </NavLink>
                    <NavLink activeclassname="active" to="/outsourcing">
                      <li>Outsourcing</li>
                    </NavLink>
                    <NavLink activeclassname="active" to="/training">
                      <li>Training</li>
                    </NavLink>
                  </ol>
                </div>
              </NavLink>
              <NavLink activeclassname="active" to="/vacancies">
                <li style={{ color: color }}>Client Vacancies</li>
              </NavLink>
              <NavLink activeclassname="active" to="/contact">
                <li style={{ color: color }}>Contact</li>
              </NavLink>
            </ul>
          </div>
          <div className="menu" onClick={handleClick}>
            <img src={icons.menuB} alt="MENU" />
          </div>
        </div>
        <div className={click ? "toggle active" : "toggle"}>
          <div onClick={handleClick}>
            <img src={icons.close} alt="CLOSE" />
          </div>
          <div className="menu-list">
            <div onClick={handleClick}>
              <NavLink exact activeclassname="active" to="/">
                About us
              </NavLink>
            </div>
            <div
              className="solution-dropdown"
              onClick={() => setDropdown(!dropdown)}
            >
              <p onClick={() => setDropdown(!dropdown)}>
                <NavLink activeclassname="active">
                Solutions
                </NavLink>
              </p>
              <img src={icons.dropdown} alt="dropdown" />
            </div>
            {dropdown ? (
              <div className="solution-mobile">
                <p onClick={handleClick}>
                  <NavLink activeclassname="active" to="/recruitment">
                    Recruitment
                  </NavLink>
                </p>
                <p onClick={handleClick}>
                  <NavLink activeclassname="active" to="/resource-consulting">
                    Human Resource Consulting
                  </NavLink>
                </p>
                <p onClick={handleClick}>
                  <NavLink activeclassname="active" to="/outsourcing">
                    Outsourcing
                  </NavLink>
                </p>
                <p onClick={handleClick}>
                  <NavLink activeclassname="active" to="/training">
                    Training
                  </NavLink>
                </p>
              </div>
            ) : null}
            <div onClick={handleClick}>
              <NavLink activeclassname="active" to="/vacancies">
                Client Vacancies
              </NavLink>
            </div>
            <div onClick={handleClick}>
              <NavLink activeclassname="active" to="/contact">
                Contact
              </NavLink>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};
