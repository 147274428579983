import React from "react";
import Text from "../text";
import styles from "./button.module.css";
import { useNavigate } from "react-router-dom";

export const Button = ({
  bg = "",
  borderColor = "",
  color = "#000",
  text = "",
  route,
  onClick,
  noRoute
}) => {
  const navigate = useNavigate();
  const inlineStyling = {
    borderRadius: "24px",
    backgroundColor: bg,
    borderColor: borderColor,
    borderWidth: ".3",
    borderStyle: "solid",
    display: "inline-block", // Set display to inline-block
    cursor: "pointer"
  };

  return (
    <div
      className={styles.container}
      style={inlineStyling}
      onClick={noRoute ? onClick : () => navigate(route)}
    >
      <Text variant="md" className={styles.button} style={{ color: color }}>
        {text}
      </Text>
    </div>
  );
};
