import React from "react";
import TextHeader from "../textHeader/TextHeader";
import Text from "../text";
import styles from "./vmg.module.css";

function VMG({ head, content }) {
  return (
    <section style={{ textAlign: "center" }} className={styles.container}>
      <TextHeader tag="h1">{head}</TextHeader>
      <Text variant="md">{content}</Text>
    </section>
  );
}

export default VMG;
