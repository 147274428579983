import React from "react";
import TextHeader from "../../Components/textHeader/TextHeader";
import styles from "./solutions.module.css";
import Text from "../../Components/text";
import { Card } from "../../Components/card/card";
import { HomeHeader } from "../../MainHeader/MainHeader";

export const ResourceConsulting = () => {
  return (
    <div style={{backgroundColor: "#FFF6D4", paddingBottom: "5rem"}}>
      <HomeHeader />
      <div className="main-padding">
        <div className={styles.resourceHeader}>
          <TextHeader tag="h2">
            We are experts in Human Resource Consulting{" "}
          </TextHeader>
          <Text variant="md" color="subsidiary">
            The fundamental blocks of building and running a stable business
            will not be complete without a competent and dependable workforce,
            which is where we shine. Our HR consulting services offer you the
            best integrated solutions for your business needs in:
          </Text>
        </div>

        <div className={styles.sectionGap}>
          <Card
            text1="Policy Formulation "
            text2="Learning and Development"
            bg1="#043032"
            bg2="#000"
            boxOneFlex="flex60"
            boxTwoFlex="flex40"
          />
          <Card
            text1="Payroll Administration"
            text2="Compensation Management"
            bg1="#C54920"
            bg2="#32BEAB"
            boxOneFlex="flex40"
            boxTwoFlex="flex60"
          />
          <Card
            text1="Performance Appraisal"
            text2="Other HR Support services"
            bg1="#9EB49C"
            bg2="#FDC00C"
            boxOneFlex="flex60"
            boxTwoFlex="flex40"
          />
        </div>
      </div>
    </div>
  );
};
