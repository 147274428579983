import { ApiRoutes } from "./apiRoutes";
import axios, { AxiosResponse } from "axios";

export const API = axios.create({
  baseURL: ApiRoutes.BASE_URL_TEST
});

/****** Function to get all About us, project and resources *******/

export function useGetJobListings() {
  /**
   * get blog details
   * @returns The response for the API request
   */
  async function getVacancies() {
    // Get BOD user data
    let response = await API.get(ApiRoutes.BASE);

    // Return response
    return response;
  }
  return getVacancies;
}

/******Function to get all About us, project by ID starts here *******/

export function useGetJobDetails() {
  /**
   * Get MACN by ID
   * @returns The response for the API request
   */
  async function getVacancyDetail(jobId) {
    // Get BOD user data
    let response = await API.get(`${ApiRoutes.BASE}${jobId}`);
    // Return response
    return response;
  }

  return getVacancyDetail;
}

export function usePostCV() {
  /**
   * Get MACN by ID
   * @returns The response for the API request
   */
  async function postCV(data) {
    // Get BOD user data
    let response = await API.post(`${ApiRoutes.SendCV}`, data);
    // Return response
    return response;
  }

  return postCV;
}
