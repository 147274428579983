import React from "react";
import { HomeHeader } from "../../MainHeader/MainHeader";
import TextHeader from "../../Components/textHeader/TextHeader";
import Text from "../../Components/text";
import style from "./solutions.module.css";

export const Recruitment = () => {
  return (
    <div
      style={{
        backgroundColor: "#FFF6D4",
        paddingBottom: "5rem",
        flex: 1,
        minHeight: "100vh"
      }}
    >
      <HomeHeader />
      <div className={`${style.recruitmentHeader} main-padding`}>
        <section className={style.recruitContainer}>
          <div className={style.recruitInner}>
            <TextHeader tag="h2" style={{paddingBottom: 0}}>Recruitment Experts</TextHeader>
          </div>
        </section>
        <section>
          <TextHeader tag="h2">We are experts in Recruitment </TextHeader>
          <Text variant="md" color="subsidiary">
            We help clients to reach their objectives through sourcing and
            placing the right talent for each job. We focus on end to end
            recruitment solutions for organizations
          </Text>
        </section>
      </div>
    </div>
  );
};
