import React, { useState } from "react";

import { HomeHeader } from "../../MainHeader/MainHeader";
import Box from "../../Components/box";
import { Button } from "../../Components/button";
import { images } from "../../assets";
import Checks from "../../Components/checks";
import VMG from "../../Components/vmg";
import styles from "./home.module.css";
import TextHeader from "../../Components/textHeader/TextHeader";
import Footer from "../footer";

const HomePage = () => {

  let checks = [
    "Are you passionate about work?",
    "Want to get connected to reputable employers?",
    "Do you want easy access to our training services?",
    "Want our professional outsourcing services?"
  ];
  const logos = [
    images.spons1,
    images.spons2,
    images.spons10,
    images.spons4,
    images.spons5,
    images.spons6,
    images.spons7,
    images.spons8,
    images.spons9,
    images.spons3
  ];
  return (
    <div>
      <div style={{ backgroundColor: "#FFF6D4", paddingBottom: "5rem" }}>
        <HomeHeader />
        <div className="main-padding">
          <Box
            head="Elevate Your Workforce with Eden Care & Resourcing Limited"
            content1="Welcome to a world of talent and potential. We are Eden Care & Resourcing Limited, your trusted partner in recruitment, outsourcing, and employee training. Harness the power of our expertise to build a thriving, skilled workforce that drives your organization's success. "
            // reverse={true}
            image={images.image1}
            textColor="primary"
            button1={
              <Button
                text="Get Started"
                bg="#FDC00C"
                borderColor="#FDC00C"
                color="#043032"
                route="recruitment"
              />
            }
            button2={
              <Button
                text="Find a Job"
                bg=""
                borderColor="rgba(51, 51, 51, 0.20)"
                color="#333333"
                route="vacancies"
              />
            }
          />
        </div>
      </div>
      <div
        className="main-padding"
        style={{ paddingTop: "4rem", paddingBottom: "5rem" }}
      >
        <Box
          head="About us"
          content1="We allow you focus on your professional goals and aspirations, we specialize in the placement of competent staff for organizations. We do not only hire & place staff, we also engage organizations in capacity building for enhanced corporate effectiveness."
          content2="Eden Care & Resourcing Limited provides businesses with support and guidance in all areas of human resource solutions – recruitment, learning & development, employee relations, payroll administration, performance management, staff outsourcing, benefits administration, workplace safety, statutory compliance, and much more."
          content3="We have experienced Faculty required to deliver local and international resources for effective impact, manpower development and measurable progress needed for any business."
          content4="We engage organizations on capacity building that would enhance corporate effectiveness."
          // reverse={true}
          image={images.image2}
          textColor="secondary"
        />
      </div>
      <div
        className="main-padding"
        style={{
          backgroundColor: "#FFF6D4",
          paddingBottom: "2rem",
          paddingTop: "4rem"
        }}
      >
        <div style={{ paddingBottom: "3rem", paddingTop: "1rem" }}>
          <Box
            head="Training"
            content1="Our company in conjunction with local and international professionals offers a number of business & financial services-related courses that would enhance operational efficiency and significantly increase revenue, whilst creating tangible values for stakeholders."
            reverse={true}
            image={images.image3}
            textColor="subsidiary"
            button1={
              <Button
                text="Get Started"
                bg="#C54920"
                borderColor="#C54920"
                color="white"
                route="training"
              />
            }
          />
        </div>
        <div style={{ paddingBottom: "3rem", paddingTop: "2rem" }}>
          <Box
            head="Talent Sourcing and Acqusition"
            content1="We help our clients to achieve their business objectives through talent sourcing and placement. We focus on end to end recruitment solutions for organisations. Exclusive services are also available for hiring Tech talents."
            content2="Hiring and grooming a dependable workforce has become a very challenging exercise, the strategy and methodology we adopt is unique and thorough and we assure you that we can provide the best match for your staffing requirements."
            // reverse={true}
            image={images.image4}
            textColor="subsidiary"
            button1={
              <Button
                text="Get Started"
                bg="#C54920"
                borderColor="#C54920"
                color="white"
                route="recruitment"
              />
            }
          />
        </div>
        <div style={{ paddingBottom: "2rem", paddingTop: "2rem" }}>
          <Box
            head="Human Resource Consulting"
            content1="The challenges to cope with the advancement of technology and the ever-growing demands of the markets are some of the major concerns of business."
            content2="Complexities of running the daily operations, managing the cashflow, providing excellent products and services, surpassing the sales revenue – these impact the business cycle and more importantly, the capacity of the organization to get ahead of the competition while maintaining customer satisfaction and increasing its bottom-line"
            reverse={true}
            image={images.image5}
            textColor="subsidiary"
            checks={[
              "HR Audit",
              "HR Restructuring",
              "Learning & Development",
              "Policy formulation",
              "HR Information System",
              "Payroll Administration",
              "Change Management",
              "Performance Appraisal",
              "Other HR Support services"
            ]}
          />
        </div>
        <div style={{ paddingBottom: "2rem" }}>
          <Box
            head="Outsourcing"
            content1="With our outsourcing services, you will enjoy some benefits and convenience:"
            image={images.image6}
            textColor="subsidiary"
            checks={[
              "Strengthened internal control and planning",
              "Reduced re-hiring, training & retraining",
              "More time to focus on productive and strategic HR functions",
              "Reduced, tedious and time-consuming administrative work",
              "Updated staff database for swift management decision-making",
              "Management of statutory requirements",
              "Fast and accurate access to information"
            ]}
            button1={
              <Button
                text="Get Started"
                bg="#C54920"
                borderColor="#C54920"
                color="white"
                route="outsourcing"
              />
            }
          />
        </div>
      </div>
      <div className="main-padding">
        <VMG
          head="Our Vision"
          content="To be sought after: As the most effective Human Resource Consulting firm in Nigeria"
        />
        <VMG
          head="Our Mission"
          content="To serve you excellently so you become our foremost brand champions"
        />
        <VMG
          head="Our Goal"
          content="To provide Service delivery at its best to enable our clients function maximally in their chosen endeavours."
        />
      </div>
      <div className={`${styles.startedMain} main-padding`}>
        <div className={styles.started}>
          <section className={styles.imgSeg}>
            <img src={images.coco} alt="IMG" className={styles.img} />
          </section>
          <section className={`${styles.items}`}>
            <div style={{ paddingBottom: "2rem" }}>
              <TextHeader tag="h2" color="white">
                Get Started
              </TextHeader>
              {checks?.length > 0 ? (
                <div style={{ paddingBottom: "1.5rem" }}>
                  {checks.map((res, index) => (
                    <Checks content={res} color="#fff" key={index} />
                  ))}
                </div>
              ) : null}
            </div>
            <Button
              text="Find Now"
              bg="#C54920"
              borderColor="#C54920"
              color="white"
              route="contact"
            />
          </section>
        </div>
      </div>
      <div className={`${styles.sponsGroup} main-padding`}>
        {logos.map((res, index) => (
          <img src={res} alt="IMG" className={styles.spons} key={index} />
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default HomePage;
