import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import styles from "./text.module.css";

/**
 *
 * @param {xs | sm | md | md2 |lg | xl | xxl} variant Specify the variant of the text.
 * @param {primary | secondary | tertiary} color Specify the color of the text.
 * @param {String} tag A valid Html Tag.
 * @returns JSX.Element
 */
const Text = forwardRef(
  (
    {
      children,
      className,
      color = "primary",
      variant = "",
      style,
      tag = "p",
      ...props
    },
    ref
  ) => {
    return React.createElement(
      tag,
      {
        className: `${styles.Text} ${styles[color]} ${styles[variant]} ${styles[tag]}  ${className}`,
        style,
        ...props,
        ref
      },
      children
    );
  }
);
Text.propType = {
  variant: PropTypes.oneOf(["xs", "sm", "md", "lg"]),
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "tertiary",
    "white",
    "subsidiary",
    "cream"
  ]),
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node,
  tag: PropTypes.string
};
export default Text;
