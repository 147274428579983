import image1 from "./images/image1.png";
import image2 from "./images/image2.png";
import image3 from "./images/image3.png";
import image4 from "./images/image4.png";
import image5 from "./images/image5.png";
import image6 from "./images/image6.png";
import image7 from "./images/image7.png";
import spons1 from "./images/spons1.png";
import spons2 from "./images/spons2.png";
import spons3 from "./images/spons3.png";
import spons4 from "./images/spons4.png";
import spons5 from "./images/spons5.png";
import spons6 from "./images/spons6.png";
import spons7 from "./images/spons7.png";
import spons8 from "./images/spons8.png";
import spons9 from "./images/spons9.png";
import spons10 from "./images/spons10.png";
import outsourcing from "./images/outsourcing.png";
import training from "./images/training.png";
import vacancy from "./images/vacancy.png";
import coco from "./images/coco.png";
import upload from "./images/upload.png";

const images = {
  upload,
  coco,
  vacancy,
  outsourcing,
  training,
  spons1,
  spons2,
  spons3,
  spons4,
  spons5,
  spons6,
  spons7,
  spons8,
  spons9,
  spons10,
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7
};

export default images;
