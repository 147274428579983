import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./footer.module.css";
import { icons } from "../../assets";
import Text from "../../Components/text";
import { Button } from "../../Components/button";

export const Footer = () => {
  const socials = [icons.social1, icons.social2, icons.social3];
  return (
    <div className={`main-padding`} style={{ backgroundColor: "#043032" }}>
      <div className={`${styles.footer}`}>
        <div style={{ textAlign: "center" }}>
          <img src={icons.logo} alt="LOGO" className={styles.logo} />
          <Text variant="md" color="cream">
            Want to receive regular client vacancy application and updates tips?
            Join our email list
          </Text>
          <Text variant="md" color="cream">
            (We promise we won't spam you)
          </Text>
        </div>
        <div className={styles.inputGroup}>
          <input placeholder="First Name" className={styles.input} />
          <input placeholder="Surname" className={styles.input} />
          <input placeholder="Email Address" className={styles.input} />
        </div>
        <div>
          <div className={styles.middle}>
            <Button
              text="Get Started"
              bg="#FDC00C"
              borderColor="#FDC00C"
              color="#043032"
            />
          </div>
        </div>
        <div style={{ textAlign: "center" }}>
          <Text variant="md" color="cream">
            For further enquiries contact us at 33 Saji Ayangade Street,
            Anthony, Maryland, Lagos
          </Text>
          <Text variant="md" color="cream">
            Nigeria OR send an email to {" "}
            <a
              href="mailto:info@edencarelimited.com"
              style={{ color: "#FFF6D4" }}
            >
              info@edencarelimited.com.
            </a>
          </Text>
          <Text variant="md" color="cream">
            Our phone lines 08023982811, 08094711700 are open Monday – Friday,
            between 8am and 5pm
          </Text>
          <div
            style={{
              marginTop: "4rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "1rem"
            }}
          >
            {socials.map((res, index) => (
              <div key={index}>
                <img src={res} alt="IMG" className={styles.socials} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
