import React, { useEffect, useState } from "react";
import { HomeHeader } from "../../MainHeader/MainHeader";
import Box from "../../Components/box";
import { Button } from "../../Components/button";
import { icons, images } from "../../assets";
import TextHeader from "../../Components/textHeader/TextHeader";
import Text from "../../Components/text";
import JobListing from "../../Components/jobListing";
import InputWithAdornment from "../../Components/inputs/InputWithAdornment";
import { useGetJobListings } from "../../api/apiClients";
import Loading from "../../Components/loading/loading";

const Vacancies = () => {
  const getJobListing = useGetJobListings();
  const [content, setContent] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getJobListing()
      .then((response) => {
        setContent(response.data.jobs);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          alert("Network error! please check your internet connection");
        } else {
          console.log({ error });
          setIsLoading(false);
        }
      });
  }, []);

  const [searchKeyword, setSearchKeyword] = useState("");

  const handleSearch = (keyword) => {
    setSearchKeyword(keyword);
  };

  // Filter content based on search keyword
  const filteredContent = content.filter(({ jobTitle, country, location }) => {
    const lowerCaseKeyword = searchKeyword.toLowerCase();
    return (
      jobTitle.toLowerCase().includes(lowerCaseKeyword) ||
      country.toLowerCase().includes(lowerCaseKeyword) ||
      location.toLowerCase().includes(lowerCaseKeyword)
    );
  });

  return (
    <div>
      <div style={{ backgroundColor: "#043032", paddingBottom: "5rem" }}>
        <HomeHeader color="#FFF6D4" />
        <div className="main-padding">
          <Box
            head="Elevate Your Workforce with Eden Care & Resourcing Limited"
            content1="Welcome to a world of talent and potential. We are Eden Care & Resourcing Limited, your trusted partner in recruitment, outsourcing, and employee training. Harness the power of our expertise to build a thriving, skilled workforce that drives your organization's success. "
            // reverse={true}
            image={images.vacancy}
            headColor="#FFF6D4"
            textColor="cream"
            button1={
              <a href="#down">
                <Button
                  text="See Openings"
                  bg="#C54920"
                  borderColor="#C54920"
                  color="white"
                  route="#down"
                />
              </a>
            }
          />
        </div>
      </div>
      <div
        className="main-padding"
        style={{ paddingTop: "4rem", paddingBottom: "2rem" }}
        id="down"
      >
        <TextHeader tag="h3" style={{ textAlign: "center" }}>
          Join the Eden Care heroes today and help us connect a nation
        </TextHeader>
        <Text variant="md" color="secondary" style={{ textAlign: "center" }}>
          {filteredContent.length} jobs in different locations
        </Text>
      </div>
      <div className="main-padding" style={{ paddingBottom: "2rem" }}>
        <InputWithAdornment
          left={
            <img
              src={icons.search}
              alt="IMG"
              style={{
                width: "18px",
                height: "18px"
              }}
            />
          }
          placeholder="Type a keyword to search"
          onChange={(e) => handleSearch(e.target.value)}
        />
      </div>
      <div className="main-padding" style={{ paddingBottom: "2rem" }}>
        {isLoading ? (
          <Loading />
        ) : filteredContent.length > 0 ? (
          filteredContent.map(({ jobTitle, country, location, _id }) => (
            <JobListing
              key={_id}
              jobTitle={jobTitle}
              country={country}
              location={location}
              id={_id}
            />
          ))
        ) : (
          <Text variant="md" style={{ textAlign: "center" }}>
            No matching content found.
          </Text>
        )}
      </div>
      {/* <div
        style={{
          textAlign: "center",
          paddingTop: "1.5rem",
          paddingBottom: "2rem"
        }}
      >
        <Text variant="md">Showing 10 of 26</Text>
        <Text
          variant="ms"
          color="secondary"
          style={{ paddingTop: "1rem", color: "#C54920", fontWeight: "700" }}
        >
          Load more
        </Text>
      </div> */}
    </div>
  );
};

export default Vacancies;
