import React from "react";
import TextHeader from "../../Components/textHeader/TextHeader";
import styles from "./solutions.module.css";
import Text from "../../Components/text";
import { Card } from "../../Components/card/card";
import { HomeHeader } from "../../MainHeader/MainHeader";
import Box from "../../Components/box";
import { images } from "../../assets";

export const Training = () => {
  return (
    <div
      style={{
        backgroundColor: "#043032",
        paddingBottom: "1rem",
        flex: 1,
        minHeight: "100vh"
      }}
    >
      <HomeHeader color="#FFF6D4"/>
      <div className="main-padding">
        <div className={styles.trainingHeader}>
          <div className={styles.trainingSection}>
            <Box
              head="Training"
              headColor="#FFF6D4"
              content1="Our company in conjunction with local and international professionals offers a number of business & financial services-related courses that would enhance operational efficiency and significantly increase revenue, whilst creating tangible values for stakeholders."
              content2="We have experienced Faculty required to deliver local and international resources for effective impact, manpower development and measurable progress needed for continually We partner organizations on capacity building that would enhance corporate effectiveness."
              content3="For your training needs, please send email to training@edencarelimited.com"
              textColor="cream"
              image={images.training}
            />
          </div>
          <div className={styles.trainingSection}>
            <Box
              head="Talents Outsourcing"
              headColor="#FFF6D4"
              textColor="cream"
              content1="Talent sourcing & Acquisition - We help our clients to achieve their business objectives through talent sourcing and placement. We focus on end to end recruitment solutions for organisations. Exclusive services are also available for hiring Tech talents."
              content2="Hiring and developing a dependable workforce has become a very challenging exercise, the strategy and methodology we adopt is unique and thorough; and we assure you that we can provide the best match for your staffing requirements. For your talent sourcing & acquisition needs, please send email to recuritment@edencarelimited.com "
              image={images.outsourcing}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
