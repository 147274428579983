import React from "react";
import { HomeHeader } from "../../MainHeader/MainHeader";
import Text from "../../Components/text";
import styles from "./contact.module.css";

export const Contact = () => {
  return (
    <div
      style={{
        backgroundColor: "#043032",
        paddingBottom: "5rem",
        flex: 1,
        minHeight: "100vh"
      }}
    >
      <HomeHeader color="#FFF6D4" />
      <div className="main-padding">
        <div className={styles.contactHeader}>
          <Text variant="md" color="cream" style={{ textAlign: "center" }}>
            For further enquiries contact us at 33 Saji Ayangade Street,
            Anthony, Maryland, Lagos Nigeria OR send an email to{" "}
            <span style={{ textDecoration: "underline" }}>
              <a href="mailto:info@edencarelimited.com" style={{color: "#FFF6D4"}}>
                info@edencarelimited.com.
              </a>
            </span>{" "}
          </Text>
          <Text
            variant="md"
            color="cream"
            style={{ textAlign: "center", paddingTop: "1rem" }}
          >
            Our phone lines 08023982811, 08094711700 are open Monday – Friday,
            between 8am and 5pm
          </Text>
        </div>
        <div style={{ marginTop: "5rem" }}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3963.664281680611!2d3.3725793000000004!3d6.563991!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8d86e41c4b23%3A0x2b4d9598c828ebbf!2s33%20Saji%20Ayangade%20St%2C%20Anthony%20105102%2C%20Lagos!5e0!3m2!1sen!2sng!4v1700172635076!5m2!1sen!2sng"
            width="100%"
            height="400"
            // style="border:0;"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
};
