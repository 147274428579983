import React from "react";
import TextHeader from "../textHeader/TextHeader";
import Text from "../text";
import images from "../../assets/images";
import styles from "./box.module.css";
import Checks from "../checks";

export default function Box({
  head,
  content1,
  content2,
  content3,
  content4,
  button1,
  button2,
  reverse,
  image,
  textColor,
  headColor,
  checks
}) {
  return (
    <div>
      <div
        className={styles.container}
        style={{ flexDirection: reverse ? "row-reverse" : "row" }}
      >
        <section className={`${styles.flexItem} ${styles.flex60}`}>
          <div>
            <TextHeader tag="h2" style={{ color: headColor }}>
              {head}
            </TextHeader>
            <Text
              variant="md"
              style={{ paddingBottom: "2rem" }}
              color={textColor}
            >
              {content1}
            </Text>
            {content2 && (
              <Text
                variant="md"
                style={{ paddingBottom: "1rem" }}
                color={textColor}
              >
                {content2}
              </Text>
            )}
            {content3 && (
              <Text
                variant="md"
                style={{ paddingBottom: "1rem" }}
                color={textColor}
              >
                {content3}
              </Text>
            )}
            {content4 && (
              <Text
                variant="md"
                style={{ paddingBottom: "1rem" }}
                color={textColor}
              >
                {content4}
              </Text>
            )}
            {checks?.length > 0 ? (
              <div style={{paddingBottom: "1.5rem"}}>
                {checks.map((res, index) => (
                  <Checks content={res} color="#333333" key={index}/>
                ))}
              </div>
            ) : null}
          </div>
          <div className={`${styles.button} ${styles.buttonMobile}`}>
            {button1}
            {button2}
          </div>
        </section>
        <section className={`${styles.flexItem} ${styles.flex40}`}>
          <img src={image} alt="IMG" className={styles.img} />
        </section>
      </div>
    </div>
  );
}
