import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import styles from "./input.module.css";
import Text from "../text";

/**
 * @param {JSX.Element | String} left This is the left adornment.
 * @param {JSX.Element | String} right This is the right adornment.
 * @returns JSX.Element
 */
const InputWithAdornment = forwardRef(
  (
    {
      left,
      right,
      error,
      id,
      name,
      className,
      fullWidth,
      success,
      disabled,
      placeholder,
      parentStyle = {},
      ...props
    },
    ref
  ) => {
    return (
      <div
        style={{
          flex: "1 0 0%",
          borderBottom: "1px solid black ",
          ...parentStyle
        }}
        className={`parent ${fullWidth ? styles.fullWidth : ""}`}
      >
        <div
          className={`${styles.root} ${className} ${
            error ? styles.errorInput : ""
          } ${success ? styles.successInput : ""} 
           w-full ${disabled ? styles.disabled : ""}`}
          style={{ paddingBottom: ".8rem" }}
        >
          {left && <>{left}</>}
          <input ref={ref} id={id} name={name} disabled={disabled} {...props} placeholder={placeholder}/>
          {right && <>{right}</>}
        </div>
        {error && (
          <Text
            tag="small"
            variant="sm"
            className="errorInput"
            style={{ color: "var(--error-color)" }}
          >
            {error}
          </Text>
        )}
        {success && (
          <Text tag="small" style={{ color: "var(--secondary-color" }}>
            {success}
          </Text>
        )}
      </div>
    );
  }
);
InputWithAdornment.propTypes = {
  left: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  right: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  error: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  fullWidth: PropTypes.bool
};
export default InputWithAdornment;
