import React from "react";
import Text from "../text";
import icons from "../../assets/icons";
import styles from "./check.module.css";

function Checks({ color = "#000", content }) {
  return (
    <div style={{ display: "flex", gap: ".9rem" }}>
      <img src={icons.mark} alt="IMG" className={styles.img} />
      <Text variant="sm" style={{ color: color, paddingBottom: ".5rem" }}>
        {content}
      </Text>
    </div>
  );
}

export default Checks;
