import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import styles from "./textHeader.module.css";

/**
 *
 * @param {xs | sm | md | md2 |lg | xl | xxl} variant Specify the variant of the text.
 * @param {primary | secondary | tertiary} color Specify the color of the text.
 * @param {String} tag A valid Html Tag.
 * @returns JSX.Element
 */
const TextHeader = forwardRef(
  (
    {
      children,
      className,
      color = "primary",
      variant = "",
      style,
      tag = "h1",
      fontWeight = "500",
      ...props
    },
    ref
  ) => {
    return React.createElement(
      tag,
      {
        className: `${styles.Text} ${styles[color]} ${styles[variant]} ${styles[tag]} ${className}`,
        style,
        ...props,
        ref
      },
      children
    );
  }
);

Text.propType = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "tertiary",
    "white",
    "subsidiary",
    "cream"
  ]),
  tag: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5"]),
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node
};
export default TextHeader;
