import close from "./icons/close.svg";
import fb from "./icons/fb.svg";
import logo from "./icons/logo.png";
import menuB from "./icons/menu-1.svg";
import dropdown from "./icons/dropdown.png";
import mark from "./icons/mark.png";
import search from "./icons/search.png";
import social1 from "./icons/social1.png";
import social2 from "./icons/social2.png";
import social3 from "./icons/social3.png";

const icons = {
  social1,
  social2,
  social3,
  mark,
  close,
  fb,
  logo,
  menuB,
  dropdown,
  search
};

export default icons;
