import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { HomeHeader } from "./MainHeader/MainHeader";
import Footer from "./Screen/footer";
import HomePage from "./Screen/home";
import ScrollToTop from "./ScrollToTop";
import "./style.scss";
import { ResourceConsulting } from "./Screen/solutions/resourceConsulting";
import { Outsourcing } from "./Screen/solutions/oursourcing";
import { Recruitment } from "./Screen/solutions/recruitment";
import { Training } from "./Screen/solutions/training";
import { Contact } from "./Screen/contact/contact";
import Vacancies from "./Screen/vacancies/vacancies";
import JobDetailsPage from "./Screen/vacancies/jobDetailsPage";

function App() {
  return (
    <div>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="resource-consulting" element={<ResourceConsulting />} />
          <Route path="outsourcing" element={<Outsourcing />} />
          <Route path="recruitment" element={<Recruitment />} />
          <Route path="training" element={<Training />} />
          <Route path="contact" element={<Contact />} />
          <Route path="vacancies" element={<Vacancies />} />
          <Route path="job/:id" element={<JobDetailsPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;

