import React from "react";
import TextHeader from "../../Components/textHeader/TextHeader";
import styles from "./solutions.module.css";
import Text from "../../Components/text";
import { Card } from "../../Components/card/card";
import { HomeHeader } from "../../MainHeader/MainHeader";

export const Outsourcing = () => {
  return (
    <div style={{backgroundColor: "#FFF6D4", paddingBottom: "5rem"}}>
      <HomeHeader />
      <div className="main-padding">
        <div className={styles.resourceHeader}>
          <TextHeader tag="h2">
            We offer excellent Outsourcing Services
          </TextHeader>
          <Text variant="md" color="subsidiary">
            With our outsourcing services, you will enjoy some benefits and
            convenience:
          </Text>
        </div>
        <div className={styles.sectionGap}>
          <Card
            text1="Strengthened internal control and planning "
            text2="Reduced re-hiring, training & retraining"
            bg1="#043032"
            bg2="#000"
            boxOneFlex="flex60"
            boxTwoFlex="flex40"
          />
          <Card
            text1="Minimal tedious and time consuming administrative work"
            text2="More time to focus on productive and strategic HR function"
            bg1="#C54920"
            bg2="#32BEAB"
            boxOneFlex="flex40"
            boxTwoFlex="flex60"
          />
          <Card
            text1="Management of statutory requirements"
            bg1="#9EB49C"
            boxOneFlex="flex60"
            boxTwoFlex="flex40"
          />
        </div>
      </div>
    </div>
  );
};
