import React from "react";
import TextHeader from "../textHeader/TextHeader";
import styles from "./card.module.css";

export const Card = ({
  reverse,
  text1,
  text2,
  bg1,
  bg2,
  boxOneFlex,
  boxTwoFlex
}) => {
  return (
    <div className={styles.main} style={{ flexDirection: "row" }}>
      {text1 ? (
        <div
          className={`${styles.container} ${
            boxOneFlex === "flex40" ? styles.flex40 : styles.flex60
          }`}
          style={{ backgroundColor: bg1 }}
        >
          <TextHeader tag="h2" style={{ paddingBottom: 0, color: "#FFF6D4" }}>
            {text1}
          </TextHeader>
        </div>
      ) : (
        <div
          className={`${styles.container} ${
            boxOneFlex === "flex40" ? styles.flex40 : styles.flex60
          }`}
        ></div>
      )}

      {text2 ? (
        <div
          className={`${styles.container} ${
            boxTwoFlex === "flex60" ? styles.flex60 : styles.flex40
          }`}
          style={{ backgroundColor: bg2 }}
        >
          <TextHeader tag="h2" style={{ paddingBottom: 0, color: "#FFF6D4" }}>
            {text2}
          </TextHeader>
        </div>
      ) : (
        <div
          className={`${styles.container} ${
            boxTwoFlex === "flex40" ? styles.flex40 : styles.flex60
          }`}
        ></div>
      )}
    </div>
  );
};
